import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./Router";
//TODO DASHBOARD, E cena para ter de PT para ENG ao fazer ligação com basse de dados
//que vai buscar o content
function App() {
  return <RouterProvider router={router} />;
}

export default App;
