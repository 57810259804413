//TODO CV page atualizar cv primeiro
import Navbar from "../Navbar/Navbar";
import React from "react";
import CVENG from "../CV/CV-PauloENG.pdf";
export default function CV() {
  return (
    <>
      <header className="fixed z-10 right-0 left-0 min-h-[50px]">
        <Navbar />
      </header>
      <div>
        <h2 className="text-center text-5xl mt-32 mb-10">CV</h2>
      </div>
      <div className="container ml-10 mr-10 mt-10 mb-10">
        <iframe src={CVENG} className="w-full h-screen" title="CV" />
      </div>
    </>
  );
}
