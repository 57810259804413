import publicacoesLista from "./publicacoesLista";
import Navbar from "../Navbar/Navbar";
import React from "react"; // Adjust the import path as per your project structure
import { Link } from "react-router-dom"; // Adjust the import path as per your project structure
export default function Blog() {
  return (
    <>
      <header className="fixed z-10 right-0 left-0 min-h-[50px]">
        <Navbar />
      </header>
      <div className="container mx-auto mt-32">
        <div className="max-w-4xl mx-auto px-4">
          <div>
            <h2 className="text-5xl mt-10 mb-10 ml-10 mr-10">Blog posts</h2>
          </div>
          <div className="space-y-12 mb-32">
            {publicacoesLista.map((publication) => (
              <div
                key={publication.title}
                className=" bg-[#2E333A] p-6 ml-10 mr-10 rounded-lg"
              >
                <h2 className="text-3xl font-bold mb-2">{publication.title}</h2>
                <p className="text-sm">
                  {new Date(publication.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </p>
                <p className="mt-2">{publication.description}</p>
                <p className="text-md mt-1">Content Covered:</p>
                <ul className="text-md list-disc pl-5">
                  {publication.dotsContentCovered.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <span className="inline-block">
                  <Link to={publication.pathPost}>
                    <h4 className="text-blue-500 text-sm underline block mt-2">
                      Read Full Text
                    </h4>
                  </Link>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
